import axios from 'axios';

const headers = {
  'Content-Type': 'application/json'
};
const apiKey = 'DE58A44A1A1F70A10DEE630DAF8A6F8E';
const getSearchURL = (indexName: string) => `https://vmp.search.windows.net/indexes/${indexName}/docs/search/?api-version=2019-05-06&api-key=${apiKey}`;

const getSearchStr = function(searchData: object): string {
  const searchText: Array<string> = [];
  for(const [k, v] of Object.entries(searchData)) {
    if (v) {
      searchText.push(`${k}:${v}`);
    }
  }
  return searchText.join(' AND ')
}

const getSearchFieldsStr = function(searchData: object): string {
  const searchFields: Array<string> = [];
  for(const [k, v] of Object.entries(searchData)) {
    if (v) {
      searchFields.push(k)
    }
  }
  return searchFields.join(',')
}

const getFilterStr = function(filterData: object): string {
  const filterText: Array<string> = [];
  for(const [k, v] of Object.entries(filterData)) {
    if (v) {
      const value = typeof(v) === 'string' ? `'${v}'` : `${v}`
      filterText.push(`${k} eq ${value}`);
    }
  }
  return filterText.join(' and ')
}

export const search = function(indexName: string, searchData: object = {}, filterData: object = {}) {
  const params = {
    "queryType": "full",
    "search": getSearchStr(searchData),
    "searchFields": getSearchFieldsStr(searchData),
    "filter": getFilterStr(filterData),
    "top": "10",
    "count": "true"
  }
  console.log(params)
  return axios.post(getSearchURL(indexName), params, {headers})
}